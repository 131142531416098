import { ROLES } from "./permissions";

export const routes = {
  home: {
    key: "home",
    slug: "home",
    to: "/home",
    title: "Dashboard",
    icon: <i className="fas fa-chart-pie"></i>,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.superAdmin.role, ROLES.admin.role, ROLES.user.role],
  },
  sms: {
    key: "sms",
    slug: "sms",
    to: "/sms",
    title: "SMS",
    icon: <i className="fas fa-sms"></i>,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.superAdmin.role,],
  },
  notify: {
    key: "notify",
    slug: "notify",
    to: "/notify",
    title: "Notificaciones",
    icon: <i className="fas fa-exclamation"></i>,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.superAdmin.role, ROLES.admin.role],
  },
  locations: {
    key: "locations",
    slug: "locations",
    to: "/locations",
    title: "Ubicaciones",
    icon: <i className="fas fa-map-marker-alt"></i>,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.superAdmin.role, ROLES.admin.role],
  },
  profile: {
    key: "profile",
    slug: "profile",
    to: "/profile",
    title: "Perfil",
    icon: <i className="fad fa-user-circle fa-swap-opacity"></i>,
    showInMenu: false,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.superAdmin.role, ROLES.admin.role, ROLES.user.role],
  },

  users: {
    key: "users",
    slug: "users",
    to: "/users",
    title: "Usuarios",
    icon: <i className="fad fa-poll-people fa-swap-opacity"></i>,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.superAdmin.role, ROLES.admin.role],
  },

  establishments: {
    key: "establishments",
    slug: "establishments",
    to: "/establishments",
    title: "Empresas",
    icon: <i className="fas fa-building"></i>,
    showInMenu: true,
    showHeader: true,
    showSearch: true,
    action: null,
    role: [ROLES.superAdmin.role],
  },

  login: {
    key: "login",
    slug: "login",
    to: "/login",
    title: "LOGIN",
    icon: null,
    showInMenu: false,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.superAdmin.role, ROLES.admin.role, ROLES.user.role],
  },

  logout: {
    key: "logout",
    slug: "logout",
    to: "/logout",
    title: "Cerrar Sesión",
    icon: <i className="fad fa-sign-out"></i>,
    showInMenu: true,
    showHeader: false,
    showSearch: false,
    action: null,
    role: [ROLES.superAdmin.role, ROLES.admin.role, ROLES.user.role],
  },
};

export const SMS_STATS = '/sms-stats';
